<script lang="ts" setup>
useServerSeoMeta({
  title: 'Amalo | Luft til vand varmepumper & Solceller | Din varmepumpe & Solcelleekspert',
  description: 'Hos Amalo er servicen i top, vi hjælper dig med Luft Til Vand Varmepumpe & Solcelle installation - Prøv vores prisberegner her på siden og få et tilbud!',
})

const incentives = [
  {
    label: 'Få et tilbud på 2 minutter',
    icon: 'heroicons-outline:clock',
  },
  {
    label: '1 års gratis servicetjek',
    icon: 'heroicons-outline:wrench',
  },
  {
    label: 'Varmegaranti',
    icon: 'material-symbols:mode-heat-outline',
  },
  {
    label: 'Landsdækkende',
    icon: 'heroicons-outline:check-circle',
  },
]

/**
 * Video subjects
 */
const videoSubjects = [
  {
    name: 'Martin Thorborg',
    city: 'København',
    posterSrc: '/images/martin_thorborg.webp',
    videoSrc: 'https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/Videoer/070421_Amalo_MartinThorborg_del2B.mp4',
    stats: {
      squareMeters: 268,
      built: 1958,
      people: '2-5',
      heatMethod: 'Radiator / Gulvvarme',
      product: '12KW Monoblock Varmepumpe',
    },
  },
  {
    name: 'Kai Egelund',
    city: 'Svendborg',
    posterSrc: '/images/kai_egelund.webp',
    videoSrc: 'https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/Kai%20Egelund%20-%20Testimonial.mp4',
    stats: {
      squareMeters: 149,
      built: 2004,
      people: '2',
      heatMethod: 'Radiator / Gulvvarme',
      product: '5KW Monoblock Varmepumpe',
    },
  },
  {
    name: 'Claus Ramvad',
    city: 'Spenstrup',
    posterSrc: '/images/claus_ramvad.webp',
    videoSrc: 'https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/Claus%20Ramvad.mp4',
    stats: {
      squareMeters: 145,
      built: 2018,
      people: '2',
      heatMethod: 'Gulvvarme',
      product: '5KW Monoblock Varmepumpe',
    },
  },
]
</script>

<template>
  <div>
    <Incentives :incentives="incentives" />

    <PageContainer>
      <div
        class="mx-auto pr-0 md:pr-5 sm:!bg-none"
        style="
             background-image: url(https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/wave-mobile.png?width=216&amp;name=wave-mobile.png);
             background-position: right 0;
             background-repeat: no-repeat;
             background-size: 216px 150px;
            "
      >
        <div class="flex lg:items-center gap-12 flex-col lg:flex-row">
          <div class="flex flex-1 flex-col justify-center relative max-w-2xl">
            <h1 class="text-blue-900 text-2xl sm:text-5xl leading-tight font-bold mt-[12px] md:mt-12 w-[240px] sm:w-auto">
              Få et gratis tilbud på<br>en varmepumpe
            </h1>
            <div class="text-blue-900 text-sm sm:text-lg md:mt-8 mt-5">
              <p>Et tilbud inkluderer</p>
            </div>

            <div class="space-y-2 md:space-y-3 py-4">
              <span class="flex flex-inline items-center gap-4">
                <span class="w-[24px] h-[24px] rounded-full bg-blue-200 grid place-items-center">
                  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.125 1.25a.89.89 0 0 1-.273.629l-7 7a.848.848 0 0 1-.602.246.866.866 0 0 1-.629-.246l-3.5-3.5a.866.866 0 0 1-.246-.629c0-.492.383-.875.875-.875.219 0 .438.11.602.273L5.25 7.02 11.621.648a.89.89 0 0 1 .629-.273c.465 0 .875.383.875.875Z" fill="#2F78DB" />
                  </svg>
                </span>
                <span class="flex flex-1 text-sm sm:text-base font-normal text-blue-900">
                  En luft til vand-varmepumpe
                </span>
              </span>

              <span class="flex flex-inline items-center gap-4">
                <span class="w-[24px] h-[24px] rounded-full bg-blue-200 grid place-items-center">
                  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.125 1.25a.89.89 0 0 1-.273.629l-7 7a.848.848 0 0 1-.602.246.866.866 0 0 1-.629-.246l-3.5-3.5a.866.866 0 0 1-.246-.629c0-.492.383-.875.875-.875.219 0 .438.11.602.273L5.25 7.02 11.621.648a.89.89 0 0 1 .629-.273c.465 0 .875.383.875.875Z" fill="#2F78DB" />
                  </svg>
                </span>
                <span class="flex flex-1 text-sm sm:text-base font-normal text-blue-900">
                  Installation af din varmepumpe
                </span>
              </span>

              <span class="flex flex-inline items-center gap-4">
                <span class="w-[24px] h-[24px] rounded-full bg-blue-200 grid place-items-center">
                  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.125 1.25a.89.89 0 0 1-.273.629l-7 7a.848.848 0 0 1-.602.246.866.866 0 0 1-.629-.246l-3.5-3.5a.866.866 0 0 1-.246-.629c0-.492.383-.875.875-.875.219 0 .438.11.602.273L5.25 7.02 11.621.648a.89.89 0 0 1 .629-.273c.465 0 .875.383.875.875Z" fill="#2F78DB" />
                  </svg>
                </span>
                <span class="flex flex-1 text-sm sm:text-base font-normal text-blue-900">
                  Afkobling af dit gamle fyr
                </span>
              </span>
            </div>
            <DawaInput type="HEAT_PUMP_INSTALLATION" />

            <div class="col-span-5">
              <div class="flex flex-wrap w-auto gap-2 justify-start mt-6 xl:mt-10">
                <GoogleReviews class="w-full sm:w-auto" />

                <FacebookReviews class="w-full sm:w-auto" />

                <!-- <div class="flex flex-col items-center mr-8">
                  <img src="//6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/raw_assets/public/amalo-cms-theme/images/people.png?width=88&amp;height=24&amp;name=people.png" alt="Customer Avatars" width="88" height="24" class="mb-2" srcset="//6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/raw_assets/public/amalo-cms-theme/images/people.png?width=44&amp;height=12&amp;name=people.png 44w, //6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/raw_assets/public/amalo-cms-theme/images/people.png?width=88&amp;height=24&amp;name=people.png 88w, //6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/raw_assets/public/amalo-cms-theme/images/people.png?width=132&amp;height=36&amp;name=people.png 132w, //6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/raw_assets/public/amalo-cms-theme/images/people.png?width=176&amp;height=48&amp;name=people.png 176w, //6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/raw_assets/public/amalo-cms-theme/images/people.png?width=220&amp;height=60&amp;name=people.png 220w, //6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/raw_assets/public/amalo-cms-theme/images/people.png?width=264&amp;height=72&amp;name=people.png 264w" sizes="(max-width: 88px) 100vw, 88px">
                  <span class="font-bold text-center xl:text-left text-sm lg:text-base text-blue-900">
                    +1.500 glade kunder
                  </span>
                </div> -->
              </div>
            </div>
            <div class="hidden absolute right-0 xl:top-48 lg:block">
              <p class="rotate-6 font-bold text-base text-center mb-2 w-[200px] xl:w-[220px] text-blue-900">
                Se hurtigt hvad du kan spare ved at skifte til en varmepumpe
              </p>

              <svg class="text-blue-600" width="165" height="110" viewBox="0 0 165 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#a)" fill-rule="evenodd" clip-rule="evenodd" fill="currentColor">
                  <path d="M126.12 3.452c13.958-6.967 30.256-8.902 47.517 3.23a1.5 1.5 0 0 0 1.726-2.454C157-8.678 139.486-6.572 124.781.768c-6.256 3.122-12.019 7.198-17.153 11.465-8.469-2.986-20.134-2.742-32.263.977-27.322 8.378-57.782 34.6-61.694 83.843a1.5 1.5 0 1 0 2.99.238c3.808-47.917 33.341-73.166 59.583-81.212 10.934-3.353 21.164-3.682 28.699-1.55-5.672 4.986-10.472 10.048-14.187 14.012-6.854 7.312-9.964 14.372-10.278 20.252-.315 5.902 2.217 10.63 6.557 12.814 4.317 2.171 10.007 1.617 15.633-2.123 5.627-3.74 11.291-10.701 15.867-21.64 2.354-5.629 2.652-10.643 1.13-14.856-1.5-4.154-4.69-7.309-8.918-9.434 4.676-3.783 9.833-7.336 15.373-10.102ZM108.2 15.675c.353.152.698.311 1.034.477 3.793 1.865 6.408 4.529 7.609 7.855 1.203 3.328 1.071 7.547-1.076 12.679-4.425 10.579-9.771 16.984-14.759 20.3-4.99 3.315-9.52 3.502-12.625 1.94-3.082-1.55-5.173-5.03-4.91-9.973.266-4.967 2.938-11.39 9.472-18.36 4.01-4.28 9.176-9.713 15.255-14.918Z" />
                  <path d="M2.797 79.582a1.5 1.5 0 0 0-.375 2.088L13.877 98.14a1.5 1.5 0 0 0 2.12.352L30.502 87.83a1.5 1.5 0 1 0-1.777-2.417L15.46 95.164 4.885 79.957a1.5 1.5 0 0 0-2.088-.375Z" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" transform="scale(-1 1) rotate(-.42 81.504 22449.125)" d="M0 0h163.796v107.816H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div class="flex mt-6 ml-auto text-center lg:mt-0">
            <span class="w-auto super-hero-image-container">
              <NuxtImg provider="cloudinary" format="webp" alt="Rasmus Høtoft fra Amalo" title="Amalo Varmepumpe" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/rasmushoetoft-amalo.png" width="450" class="lg:min-w-[450px] " />
            </span>
          </div>
        </div>
      </div>

      <div
        style="
background-image: url('');
background-position: 80% -40px;
background-repeat: no-repeat;
background-size: 356px 243px; height: 243px; overflow:visible;"
      >
        <div class="text-base font-bold text-blue-900 mb-8 flex justify-center pt-[120px]">
          Du kender os måske fra
        </div>
        <div class="grid lg:grid-cols-5 grid-cols-2 justify-items-center items-center gap-[48px] mx-0 lg:mx-48">
          <img src="https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/raw_assets/public/amalo-cms-theme/images/lorry.svg" alt="TV2 Lorry" class="object-cover sm:w-auto w-[100px]">
          <img src="https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/raw_assets/public/amalo-cms-theme/images/politiken.svg" alt="TV2 Lorry" class="object-cover sm:w-auto w-[100px]">
          <img src="https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/raw_assets/public/amalo-cms-theme/images/bt.svg" alt="BT" class="object-cover sm:w-auto w-[100px]">
          <img src="https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/raw_assets/public/amalo-cms-theme/images/finans.svg" alt="Finans" class="object-cover sm:w-auto w-[100px]">
          <img src="https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/raw_assets/public/amalo-cms-theme/images/go.svg" alt="GoMorgen Danmark" class="object-cover sm:w-auto w-[100px]">
        </div>
      </div>

      <div
        class="flex mt-40 lg:mt-24 w-full snap-x snap-mandatory overflow-x-auto no-scrollbar gap-2 lg:grid lg:grid-cols-3 lg:gap-4"
      >
        <div
          v-for="subject in videoSubjects" :key="subject.name"
          class="z-5 relative group flex flex-col justify-end shrink-0 snap-start snap-always rounded-xl bg-white h-[434px] lg:h-[600px] w-[314px] lg:w-auto bg-no-repeat bg-cover bg-center"
          :style="{ backgroundImage: `url(${subject.posterSrc})` }"
        >
          <div class="absolute inset-0 flex justify-center items-center">
            <span class="flex h-20 w-20 relative">
              <span class="group-hover:animate-attention absolute inline-flex h-full w-full rounded-full border border-blue-300 border-opacity-40" />
              <span class="group-hover:animate-attention-sm absolute inline-flex h-[90%] w-[90%] my-[5%] mx-[5%] rounded-full border border-blue-300 border-opacity-50" />
              <DialogRoot>
                <DialogTrigger as-child>
                  <button type="button" class="relative inline-flex rounded-full h-full w-full p-2 bg-white  my-auto mx-auto shadow cursor-pointer items-center justify-center" aria-label="Open">
                    <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2.53125 17.1875C1.49219 17.1875 0.75 16.3711 0.75 15.4062V2.34375C0.75 1.41602 1.49219 0.599609 2.53125 0.599609C2.82812 0.599609 3.16211 0.673828 3.45898 0.859375L14.1465 7.39062C14.666 7.6875 15 8.28125 15 8.875C15 9.50586 14.666 10.0996 14.1465 10.3965L3.45898 16.9277C3.16211 17.1133 2.82812 17.1875 2.53125 17.1875Z" fill="#2F78DB" />
                    </svg>
                  </button>
                </DialogTrigger>
                <DialogPortal>
                  <DialogOverlay class="bg-black/40 data-[state=open]:animate-slideUpAndFade fixed inset-0 z-30" />
                  <DialogContent class="fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[650px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] focus:outline-none z-[100]">
                    <DialogTitle class="text-blue-900 m-0 text-lg font-semibold">
                      Se film med {{ subject.name }}
                    </DialogTitle>
                    <VideoPlayer :src="subject.videoSrc" class="w-full" />
                    <DialogClose />
                  </DialogContent>
                </DialogPortal>
              </DialogRoot>
            </span>
          </div>

          <div class="p-6 text-white">
            <p class="text-lg font-bold">
              {{ subject.name }}
            </p>
            <p class="text-base font-normal">
              {{ subject.city }}
            </p>

            <div class="mt-3 space-y-2">
              <p class="text-base font-normal flex items-center">
                <Icon name="fa-solid:expand-alt" class="text-2xl min-w-[25px] w-[25px] max-w-[25px] mr-3" />
                {{ subject.stats.squareMeters }} m<sup>2</sup>
              </p>

              <p class="text-base font-normal flex items-center">
                <Icon name="fa-solid:calendar" class="text-xl min-w-[25px] w-[25px] max-w-[25px] mr-3" />
                {{ subject.stats.built }}
              </p>

              <p class="text-base font-normal flex items-center">
                <Icon name="fa-solid:users" class="text-2xl min-w-[25px] w-[25px] max-w-[25px] mr-3" />
                {{ subject.stats.people }} personer
              </p>

              <p class="text-base font-normal flex items-center">
                <Icon name="fa-solid:thermometer-three-quarters" class="text-xl min-w-[25px] w-[25px] max-w-[25px] mr-3" />
                {{ subject.stats.heatMethod }}
              </p>

              <p class="text-base font-normal flex items-center">
                <Icon name="streamline:hotel-air-conditioner-solid" class="text-xl min-w-[25px] w-[25px] max-w-[25px] mr-3" />
                {{ subject.stats.product }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="flex flex-col-reverse gap-12 lg:flex-row pt-[100px]">
        <div class="flex flex-col lg:flex-1 lg:justify-center">
          <p class="text-sm font-bold lg:text-base mb-6 uppercase text-blue-500">
            SÅDAN FUNGERER DET
          </p>

          <div>
            <h2 class="text-2xl font-bold lg:text-5xl leading-tight tracking-tight text-blue-900">
              Svar på nogle simple spørgsmål –<br>  og få et tilbud i dag
            </h2>

            <div class="pt-4 max-w-2xl text-base sm:text-xl text-blue-900">
              <p style="font-size: 20px;">
                <span style="color: #0e2f57;">Det bliver ikke nemmere at få en varmepumpe. Alt hos Amalo foregår 100 % digitalt, men bare rolig, vi holder dig i hånden hele vejen.</span>
              </p><br>
            </div>

            <div class="flex flex-col gap-4 md:flex-row md:gap-2 mt-2">
              <NuxtLink to="/faa-et-gratis-tilbud-indenfor-24-timer" class="inline-flex items-center justify-center border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 border-blue-500 text-white bg-blue-500 hover:bg-blue-400 hover:border-blue-400 px-8 py-4 text-lg font-bold" rel="">
                Få et tilbud
              </NuxtLink>

              <NuxtLink to="/luft-til-vand-varmepumpe" class="inline-flex items-center justify-center border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-blue-500 bg-blue-200 hover:bg-blue-300 px-8 py-4 text-lg font-bold" rel="">
                Vores varmepumpeløsninger
              </NuxtLink>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-center justify-center lg:flex-shrink pt-24">
          <img src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/Rasmus%20og%20Mark%20+%20Bil.webp?width=600&amp;height=486&amp;name=Rasmus%20og%20Mark%20+%20Bil.webp" width="600" height="486" alt="Rasmus og Mark + Bil" title="Rasmus og Mark + Bil" class="" srcset="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/Rasmus%20og%20Mark%20+%20Bil.webp?width=300&amp;height=243&amp;name=Rasmus%20og%20Mark%20+%20Bil.webp 300w, https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/Rasmus%20og%20Mark%20+%20Bil.webp?width=600&amp;height=486&amp;name=Rasmus%20og%20Mark%20+%20Bil.webp 600w, https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/Rasmus%20og%20Mark%20+%20Bil.webp?width=900&amp;height=729&amp;name=Rasmus%20og%20Mark%20+%20Bil.webp 900w, https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/Rasmus%20og%20Mark%20+%20Bil.webp?width=1200&amp;height=972&amp;name=Rasmus%20og%20Mark%20+%20Bil.webp 1200w, https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/Rasmus%20og%20Mark%20+%20Bil.webp?width=1500&amp;height=1215&amp;name=Rasmus%20og%20Mark%20+%20Bil.webp 1500w, https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/Rasmus%20og%20Mark%20+%20Bil.webp?width=1800&amp;height=1458&amp;name=Rasmus%20og%20Mark%20+%20Bil.webp 1800w" sizes="(max-width: 600px) 100vw, 600px">
        </div>
      </div> -->

      <div class="relative overflow-visible pt-24">
        <!-- Contents -->

        <h3 class="text-3xl font-bold text-blue-900 py-8">
          Sådan gør du
        </h3>

        <div class="w-full grid  sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-8">
          <ULandingCard
            title="Indtast din adresse"
            description="Amalo dækker hele landet. Så du indtaster blot din bopæl, hvorefter vi giver et hurtigt indblik i, hvad du potentielt kan spare ved at få installeret en luft til vand-varmepumpe."
            color="primary"
          >
            <template #icon>
              <p class="text-xl font-medium bg-blue-200 text-blue-600 rounded-3xl w-10 h-10 grid content-center text-center">
                1
              </p>
            </template>
          </ULandingCard>

          <ULandingCard
            title="Svar på nogle simple spørgsmål."
            description="Gå yderligere i dybden, og få et skræddersyet tilbud ved at svare på nogle spørgsmål om din situation, din ejendom og dit forbrug. Har vi spørgsmål, kontakter en af vores eksperter dig."
            color="primary"
          >
            <template #icon>
              <p class="text-xl font-medium bg-blue-200 text-blue-600 rounded-3xl w-10 h-10 grid content-center text-center">
                2
              </p>
            </template>
          </ULandingCard>

          <ULandingCard
            title="Modtag et uforpligtende tilbud"
            description="Når alt er på plads, modtager du et uforpligtende tilbud. Accepterer du vores tilbud, vælger du blot en installationsdato, hvorefter vi kommer forbi og installerer din nye varmepumpe."
            color="primary"
          >
            <template #icon>
              <p class="text-xl font-medium bg-blue-200 text-blue-600 rounded-3xl w-10 h-10 grid content-center text-center">
                3
              </p>
            </template>
          </ULandingCard>
        </div>
      </div>

      <h3 class="text-3xl font-bold text-blue-900 mb-8 mt-24">
        Vi tilbyder også solcelle-løsninger og elvandvarmer
      </h3>

      <ULandingCard
        class=" p-0"
        icon="i-heroicons-photo"
        color="primary"
        orientation="horizontal"
        :ui="{
          body: {
            padding: 'pr-0 sm:pr-0 pb-0 pt-4 pb-0 sm:pb-0 lg:py-0',
            background: 'bg-white',
            base: 'gap-x-8 gap-y-4 rounded-xl flex-1 grid lg:grid-cols-2 lg:items-center px-4 bg-white',
          },
        }"
      >
        <template #icon>
          <UBadge variant="soft" size="md">
            Nyhed
          </UBadge>
        </template>
        <template #title>
          <h2 class="text-2xl font-bold lg:text-4xl leading-tight tracking-tight text-blue-900">
            Producér din egen strøm <br>med solceller på taget
          </h2>
        </template>

        <template #description>
          <div class="pt-4 max-w-2xl text-base sm:text-lg">
            Som noget helt nyt, levere Amalo også solcelle-løsninger. Ligesom det kan være svært at finde rundt i forskellige varmepumper, gælder det samme for solceller. Vi har på samme måde gennemsøgt markedet, og fundet den bedste løsning.
          </div>

          <UButton to="/solceller" size="xl" class="mt-6">
            Læs mere om solceller
          </UButton>
        </template>
        <img
          src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/solceller-2.png?width=505&amp;height=700&amp;name=solceller-2.png" width="505" height="700" title="solceller-2" srcset="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/solceller-2.png?width=253&amp;height=350&amp;name=solceller-2.png 253w, https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/solceller-2.png?width=505&amp;height=700&amp;name=solceller-2.png 505w, https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/solceller-2.png?width=758&amp;height=1050&amp;name=solceller-2.png 758w, https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/solceller-2.png?width=1010&amp;height=1400&amp;name=solceller-2.png 1010w, https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/solceller-2.png?width=1263&amp;height=1750&amp;name=solceller-2.png 1263w, https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/solceller-2.png?width=1515&amp;height=2100&amp;name=solceller-2.png 1515w" sizes="(max-width: 505px) 100vw, 505px"
          alt="Billede af et solcelleanlæg"
          class="w-full rounded-md max-h-96 object-cover"
        >
      </ULandingCard>

      <ULandingCard
        class="p-0 mt-8"
        icon="i-heroicons-photo"
        color="primary"
        orientation="horizontal"
        :ui="{
          body: {
            padding: 'pr-0 sm:pr-0 pb-0 pt-4 pb-0 sm:pb-0 lg:py-0',
            background: 'bg-white',
            base: 'gap-x-8 gap-y-4 rounded-xl flex-1 grid lg:grid-cols-2 lg:items-center px-4 bg-white',
          },
        }"
      >
        <template #icon>
          <UBadge variant="soft" size="md">
            Nyhed
          </UBadge>
        </template>
        <template #title>
          <h2 class="text-2xl font-bold lg:text-4xl leading-tight tracking-tight text-blue-900">
            Varmt vand uden afbrydelser <br> perfekt for husholdninger
          </h2>
        </template>

        <template #description>
          <div class="pt-4 max-w-2xl text-base sm:text-lg">
            Du kan hos Amalo lige nu bestille en elvandvarmer inklusiv montering og installation. En elvandvarmer er et vidunder af teknologisk innovation, som muliggør en konstant forsyning af varmt vand i dit hjem fra badeværelse til køkken, når som helst på dagen.
          </div>

          <UButton to="/shop/elvandvarmer" size="xl" class="mt-6">
            Læs mere om elvandvarmer
          </UButton>
        </template>
        <img
          src="https://amalo-warehouse.s3.eu-central-1.amazonaws.com/account-files/533e128b-3871-4460-b379-c6b100f9d937/elvandvarmer-stor-26.webp" sizes="(max-width: 505px) 100vw, 505px"
          alt="Billede af en elvandvarmer"
          class="w-full rounded-md max-h-96 object-cover"
        >
      </ULandingCard>

      <div class="py-12 flex justify-center mt-24">
        <div
          class="max-w-[740px] space-y-2 text-center" style="text-align: CENTER
"
        >
          <h3 class="text-blue-900 font-bold text-2xl lg:text-5xl leading-tight">
            Glade og tilfredse kunder
          </h3>

          <p class="md:text-[20px] text-gray-500 text-base pt-2 pb-4 md:py-4">
            Vi sætter stor pris på vores kunders feedback. Det varmer når gode anmeldelser lander og det arbejder vi hårdt for at de gør.
          </p>
        </div>
      </div>

      <LazyTrustpilot />

      <div class="pb-12 flex mt-36">
        <div
          class="max-w-[740px] space-y-2"
        >
          <UBadge variant="subtle" size="md" color="emerald">
            Det miljørigtige valg
          </UBadge>

          <h3 class="text-blue-900 pt-2 font-bold text-2xl lg:text-4xl leading-tight">
            Hvorfor vælge en varmepumpe?
          </h3>

          <p class="text-gray-500 md:text-[20px] text-base pt-2 pb-4 md:py-4">
            Der er flere gode grunde til at vælge en varmepumpe. Her er nogle af fordelene ved at vælge en varmepumpe i stedet for et olie- eller gasfyr.
          </p>
        </div>
      </div>

      <div class="relative overflow-visible">
        <!-- Contents -->

        <div class="w-full grid  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-8">
          <ULandingCard
            title="Billig varme til dit hus"
            description="Med en SCOP-faktor op til 5 får du en af markedets absolut mest energieffektive løsninger til dit hus."
            color="primary"
          >
            <template #icon>
              <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/home.png" height="60" alt="Billig varme til dit hus ikon" />
            </template>
          </ULandingCard>

          <ULandingCard
            title="Samme stabile ydelse i frostvejr"
            description="Vores 5 kW varmepumpe leverer også 5 kW i minusgrader – det er unikt og giver dig et stabilt varmesystem."
            color="primary"
          >
            <template #icon>
              <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/frost.png" height="60" alt="Samme stabile ydelse i frostvejr ikon" />
            </template>
          </ULandingCard>

          <ULandingCard
            title="Automatisk varmestyring"
            description="Når udetemperaturen skifter, justerer varmepumpen selv effekten og giver optimalt indeklima og lavest forbrug."
            color="primary"
          >
            <template #icon>
              <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/temp-up.png" height="60" alt="Automatisk varmestyring ikon" />
            </template>
          </ULandingCard>

          <ULandingCard
            title="Miljørigtig opvarmningsform"
            description="Med en luft til vand-varmepumpe genbruger du varmen i udeluften til at opvarme din bolig og dit brugsvand."
            color="primary"
          >
            <template #icon>
              <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/leaf.png" height="60" alt="Miljørigtig opvarmningsform ikon" />
            </template>
          </ULandingCard>

          <ULandingCard
            title="Kan installeres hurtigt"
            description="Du behøver ikke vente på energitilskuddet, og samlet set får du en billigere løsning ved ikke at vente."
            color="primary"
          >
            <template #icon>
              <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/amalo-levering.png" height="60" alt="Kan installeres hurtigt ikon" />
            </template>
          </ULandingCard>

          <ULandingCard
            title="Besparelser på op til 70 %"
            description="På et gammelt oliefyr kan besparelsen være helt op til 70 %. Har du et gasfyr, er der også penge at hente."
            color="primary"
          >
            <template #icon>
              <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/badge-percent.png" height="60" alt="Besparelser på op til 70 % ikon" />
            </template>
          </ULandingCard>
        </div>
      </div>

      <div class="pb-12 flex justify-center mt-32">
        <div
          class="max-w-[740px] space-y-2 text-center"
        >
          <h3 class="text-blue-900 font-bold text-2xl lg:text-4xl leading-tight">
            Vores eksperter har taget valget for dig
          </h3>

          <p class="text-gray-500 md:text-[20px] text-base pt-2 pb-4 md:py-4">
            Det er en jungle at skifte sit gamle fyr. Slip for valg af varmepumpemærke, pumpetype og VVS’er, og brug tiden på noget andet. Amalo Smart er den mest økonomiske luft til vand-varmepumpeløsning på markedet.
          </p>
        </div>
      </div>

      <img src="https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/amalosmart.png?width=2264&amp;height=1034&amp;name=amalosmart.png" class="hs-image-widget " width="2264" height="1034" style="max-width: 100%; height: auto;" alt="amalosmart" title="amalosmart" loading="lazy" srcset="https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/amalosmart.png?width=1132&amp;height=517&amp;name=amalosmart.png 1132w, https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/amalosmart.png?width=2264&amp;height=1034&amp;name=amalosmart.png 2264w, https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/amalosmart.png?width=3396&amp;height=1551&amp;name=amalosmart.png 3396w, https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/amalosmart.png?width=4528&amp;height=2068&amp;name=amalosmart.png 4528w, https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/amalosmart.png?width=5660&amp;height=2585&amp;name=amalosmart.png 5660w, https://6517230.fs1.hubspotusercontent-na1.net/hubfs/6517230/amalosmart.png?width=6792&amp;height=3102&amp;name=amalosmart.png 6792w" sizes="(max-width: 2264px) 100vw, 2264px">

      <div class="pb-12 flex mt-36">
        <div
          class="max-w-[740px] space-y-2"
        >
          <UBadge variant="subtle" size="md">
            Den bedste løsning
          </UBadge>

          <h3 class="text-blue-900 pt-2 font-bold text-2xl lg:text-4xl leading-tight">
            Hvorfor vælge Amalo Smart?
          </h3>

          <p class="text-gray-500 md:text-[20px] text-base pt-2 pb-4 md:py-4">
            Der er flere gode grunde til at vælge en varmepumpe. Her er nogle af fordelene ved at vælge en varmepumpe i stedet for et olie- eller gasfyr.
          </p>
        </div>
      </div>

      <div class="w-full grid  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-8">
        <ULandingCard
          title="Super kompakt, fylder næsten ingenting"
          description="Målene er 50x56x19 cm (HxBxD), så det er væsentligt mindre end de gængse varmtvandsbeholdere på markedet."
          color="primary"
        >
          <template #icon>
            <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/image%20(34).png" height="60" alt="Super kompakt, fylder næsten ingenting ikon" />
          </template>
        </ULandingCard>

        <ULandingCard
          title="Du løber aldrig tør for varmt vand"
          description="I modsætning til en varmtvandsbeholder, så kommer du ikke til at mangle varmt vand."
          color="primary"
        >
          <template #icon>
            <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/image%20(35).png" height="60" alt="Du løber aldrig tør for varmt vand ikon" />
          </template>
        </ULandingCard>

        <ULandingCard
          title="Bruger kun strøm ved varmt vand"
          description="Intet tomgangstab fra varmtvandsbeholderen. Amalo Smart bruger kun strøm, når du tænder for hanen."
          color="primary"
        >
          <template #icon>
            <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/image%20(36).png" height="60" alt="Bruger kun strøm ved varmt vand ikon" />
          </template>
        </ULandingCard>

        <ULandingCard
          title="Tilkalker ikke"
          description="Traditionelle varmtvandsbeholdere kalker til med jævne mellemrum. Amalo Smart tilkalker aldrig."
          color="primary"
        >
          <template #icon>
            <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/image%20(37).png" height="60" alt="Tilkalker ikke ikon" />
          </template>
        </ULandingCard>

        <ULandingCard
          title="Støjsvag - larmer ikke"
          description="~55 db er det højeste lydniveau, der kan komme fra Amalo Smart. Ca. samme lydniveau som fra en opvaskemaskine."
          color="primary"
        >
          <template #icon>
            <NuxtImg class="pb-4" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/image%20(38).png" height="60" alt="Lydsvag - larmer ikke ikon" />
          </template>
        </ULandingCard>

        <ULandingCard
          title="Behøver ikke at varme vandet til +55C°"
          description="Med Amalo Smart kan du køre med væsentligt lavere brugsvandstemperaturer og spare mange penge."
          color="primary"
        >
          <template #icon>
            <NuxtImg class="pb-4 object-contain" provider="cloudinary" src="https://6517230.fs1.hubspotusercontent-na1.net/hub/6517230/hubfs/heat.png" height="60" alt="Behøver ikke at varme vandet til +55C° ikon" />
          </template>
        </ULandingCard>
      </div>
    </PageContainer>
  </div>
</template>

<style>
.dawa-autocomplete-suggestions {
    @apply z-[9999] overflow-y-auto inset-x-0 absolute bg-white shadow-md rounded-md text-left p-0 mt-1 max-h-[200px]
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion {
    @apply m-0 list-none cursor-pointer py-1.5 px-2.5 text-blue-900 border-t border-blue-300
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    @apply border-none rounded-t-md
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:last-child {
  @apply rounded-b-md
}

.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion.dawa-selected,
.dawa-autocomplete-suggestions .dawa-autocomplete-suggestion:hover {
    @apply bg-blue-200
}
</style>
